import(/* webpackMode: "eager", webpackExports: ["EnrolmentSection"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/enrolment/enrolment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EverySection"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/every/section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Feature"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/feature/section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/intro/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RetroSection"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/retro/section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slogan"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/slogn/slogan.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpaceDiv"] */ "/home/leikang/halfview/apps/workspace/app/[workspaceId]/home/space/space.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootHeader"] */ "/home/leikang/halfview/apps/workspace/components/root-header.tsx");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/apps/workspace/components/theme-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/apps/workspace/registry/default/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/leikang/halfview/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/leikang/halfview/node_modules/.pnpm/next@14.2.16_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
